// Main App Colours

const tintColor = '#2F95dC';

export const colors = {
  primary: "#1A1A1A",
  secondary: "#FEB130",
  text: "#292929",
  textSecondary: "#535560",
  border: "#DEDEDE",
  blue: "#3498DB",
  blueSecondary: "#2F87C2",
  red: "#E74C3C",
  redSecondary: "#CF4536",
  green: "#2ECC71",
  greenSecondary: "#29B362",
  yellow: "#F39C12",
  yellowSecondary: "#D98B0F",
  darkGrey: "#24252A",
  white: "#FFFFFF",
  whiteSecondary: "#C1C1C1",
};
