import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    Image
} from "react-native";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';

const HeaderWeb = (props) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Image
        resizeMode="contain"
        style={{
          width: 130,
          height: 70,
        }}
        source={{
          uri: 'https://images.squarespace-cdn.com/content/v1/60f6d3808aec8d6e0a828484/09df4c9d-246a-4e0c-b49f-8dbdd9684bc4/babuls-main-2-yellow.png?format=1500w',
        }}
      />
    </View>
  );
};

export default HeaderWeb

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});
