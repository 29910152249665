import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    TouchableHighlight,
    Linking,
    Image
} from "react-native";

import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';


function DrawerContent(props) {
  return (
  <DrawerContentScrollView {...props} contentContainerStyle={styles.container}>

    <View style={styles.drawer}>
      <Image
        resizeMode="contain"
        style={styles.companyLogo}
        source={require('../assets/logo-cafespice-header.png')}
      />

      <View style={styles.menu}>
        <DrawerItemList
          {...props}
          style={styles.label}
          labelStyle={styles.labelText}
          activeTintColor={colors.white}
          inactiveTintColor={colors.white}
        />
      </View>


      <View style={styles.legal}>
        <Text style={styles.legalText}>Copyright Babul's 2023. All Rights Reserved. Powered by <Text style={{color: colors.secondary }}
        onPress={() => Linking.openURL('https://fyre.uk')}>Fyre</Text>.</Text>
      </View>
    </View>

  </DrawerContentScrollView>
)
}

export default DrawerContent;

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.primary,
    },
    drawer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingVertical: 30,
      paddingHorizontal: 10,
    },
    companyLogo: {
      width: 80,
      height: 80,
      justifyContent: 'flex-start',
      marginBottom: 30,
      marginHorizontal: 20
    },
    menu: {
      flex: 1,
      justifyContent: 'stretch',
    },
    label: {
      width: 200,
    },
    labelText: {
      paddingHorizontal: 10,
      fontSize: 18,
      fontFamily: fonts.bold,
      color: colors.secondary,
    },
    account: {
      flex: 1,
      justifyContent: 'end',
      marginTop: 20,
      paddingHorizontal: 50,
    },
    accountLogout: {
      paddingVertical: 20,
    },
    accountLogoutText: {
      fontSize: 16,
      color: colors.white,
      fontFamily: fonts.bold
    },
    legal: {
      flex: 1,
      justifyContent: 'flex-end',
      paddingHorizontal: 20,
    },
    legalText: {
      fontSize: 12,
      fontFamily: fonts.bold,
      color: colors.text,
    }
});
