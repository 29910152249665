// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyDyXVONAuW8CdcNoyvYHQjyLRiOzTHkvXs",
  authDomain: "fyre-babuls.firebaseapp.com",
  projectId: "fyre-babuls",
  storageBucket: "fyre-babuls.appspot.com",
  messagingSenderId: "1037394886203",
  appId: "1:1037394886203:web:a00b7849883eb7c662e61e",
  measurementId: "G-JKEL6NQYRY"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyC-DsWeMrcWpnLHcWdSXKuJ2vtJbA5dLGs",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:3c2cbbff655c8547e8dec1",
  measurementId: "G-KLRDX1529N"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
