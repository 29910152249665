import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { hotjar } from 'react-hotjar';

import { PersistGate } from 'redux-persist/integration/react'


import * as firebase from 'firebase';
import 'firebase/firestore';

// Redux
import { Provider } from 'react-redux';
import store from './store';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Firebase
import { firebaseCompanyConfig, firebaseBackOfficeConfig } from './constants/firebase';

import Main from './Main'

import { useFonts } from 'expo-font';

import MetaData from './components/MetaData'

// Assign Company ID - I know we shouldn't use global variables
global.companyId = "pQoq9mvJkSOyWTXimgoB"
global.branchId = null


//HotJar tracking
const hjid = 3106677
const hjsv = 6
hotjar.initialize(hjid, hjsv);

// Identify the user
hotjar.identify('USER_ID', { userProperty: 'value' });

// Make sure to call `loadStripe` outside of a component’s render to avoid
// Stripe Publishable Key
const stripePromise = loadStripe('pk_live_51I62dSETGhC5ebfLICqoFDxCJmYwaHr1uDHM47qrbldhsly20Xv2VY1IKvmwfGGntqOXbO3MpbPOzxL6dRGz1Hb700iSOGD3dV');
//const stripePromise = loadStripe('pk_test_51I62dSETGhC5ebfLOMFWO8gaJS8zXI5wNdFRMFijf5zzxF9Tql3gKZzHPbHKfC8CsCAdZXegDDkp2By6Dg5QZyyz003APJjBZw');

const WindowDimensions= (()=>{
    global.windowWidth = useWindowDimensions().width;
    global.windowHeight = useWindowDimensions().height;
    return (<></>);
})

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseCompanyConfig);
  global.backendFirebase = firebase.initializeApp(firebaseBackOfficeConfig, "backoffice")
}

function App() {
    return (
      <Main />
    )
}

// Main App
export default props => {

  let [fontsLoaded] = useFonts({
    'Inter-Bold': require('./assets/fonts/Inter-Bold.ttf'),
    'Inter-SemiBold': require('./assets/fonts/Inter-SemiBold.ttf'),
    'Inter-Medium': require('./assets/fonts/Inter-Medium.ttf'),
  });


  if (!fontsLoaded) {
    return <View></View>;
  } else {
    return (
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <MetaData />
          <App />
          <WindowDimensions />
        </Elements>
      </Provider>
    );
  }
}
